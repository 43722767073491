import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import { Heading2 } from '../components/Typography/Typography'
import TextDecor from '../components/TextDecor/TextDecor'
import InfoBox from '../components/InfoBox/InfoBox'
import OtherModelsSlider from '../components/Sliders/OtherModelsSlider/OtherModelsSlider'
import {
  TRUNCATED_HERO_TYPES,
  TRUNCATED_HERO_BOX_SIDE_TYPES,
} from '../constants/truncatedHeroTypes'
import { TEXT_DECOR_TYPES } from '../constants/textDecorTypes'
import { BG_TYPES } from '../constants/bgTypes'
import {
  bgTheme,
  replaceAtToSpan,
  buildAction,
  generateHero,
} from '../common/utils'
import FlexContentBlocks from '../common/queries/FlexContentBlocks'
import mapOtherModelsToList from '../common/queries/mapOtherModelsToList'
import HTMLBody from '../components/HTMLBody'

const EscortAgencyPage = ({ data }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const page = data.wp.page?.aboutAcf
  const { edges: models } = data.wp.models
  const seoData = data.wp.page?.seo
  let heroAction
  const contentBlocks = page.contentBlocks.content

  const {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  } = generateHero(page)

  const otherModels = useMemo(
    () => models.filter((model) => !model.node.acf.comingSoon),
    [models],
  )

  const mappedOtherModels = mapOtherModelsToList(otherModels)

  const {
    otherModelsSliderImages,
    otherModelsSliderDetails,
  } = mappedOtherModels

  const otherModelsBg = bgTheme(data.wp.page?.aboutAcf.relatedModelsBg)

  if (page.heroFields.action.showAction !== null) {
    heroAction = buildAction(page.heroFields.action.action)
  }

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <Container
        bg={[heroBgColorMobile, '', heroBgColorDesktop]}
        color={[heroColorMobile, '', heroColorDesktop]}
        pb={['', '', '2.5rem']}
      >
        <TruncatedHero
          heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
          heroBoxSideType={TRUNCATED_HERO_BOX_SIDE_TYPES.left}
          heroSlantType={slantDirection || 'right'}
          heroImages={heroImages}
          gradient={hasBgGradient ? bgGradient : null}
          opacityImg={0.8}
          long={isHeroHigh}
        >
          <Container mt="-6rem">
            <InfoBox
              tag="About us"
              title={
                <Heading2
                  as="h1"
                  dangerouslySetInnerHTML={{
                    __html: replaceAtToSpan(page.heroFields.infoboxTitle),
                  }}
                />
              }
              content={
                <HTMLBody opacity="1" html={page.heroFields.infoboxContent} />
              }
              action={page.heroFields.action.showAction && heroAction}
              bgOpacity={infoBoxOpacity}
              bg={infoBoxBg}
              full
            />
          </Container>
        </TruncatedHero>
      </Container>

      <Container position="relative" overflow="hidden">
        <TextDecor label="about us" textDecorType={TEXT_DECOR_TYPES.right} />

        <FlexContentBlocks pageData={data} {...{ contentBlocks }} />
      </Container>

      <Container
        color={otherModelsBg === BG_TYPES.black ? 'white' : 'black'}
        inView={inView}
      >
        <div className="uiAnimBottom">
          <div ref={ref}>
            {inView ? (
              <OtherModelsSlider
                bg={otherModelsBg}
                images={otherModelsSliderImages}
                details={otherModelsSliderDetails}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default EscortAgencyPage

export const query = graphql`
  query {
    wp {
      page(id: "escort-agency", idType: URI) {
        seo {
          ...seoFragment
        }
        aboutAcf {
          relatedModelsBg
          heroFields {
            slantDirection
            infoboxTitle
            infoboxContent
            heroHeight
            boxBackgroundOpacity
            boxBackgroundColor
            bgMobile
            bgGradient
            bgDesktop
            backgroundMobile {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundDesktop {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          contentBlocks {
            content {
              ... on wp_Page_Aboutacf_ContentBlocks_Content_PictureAndContentBox {
                invertOnMobile
                fieldGroupName
                contentColTheme
                imageOrVideo
                cincopaVideoId
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                negativeMargin
                pictureBoxType
                withInner
                title
                addSpacings
                content
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Aboutacf_ContentBlocks_Content_Callout {
                fieldGroupName
                bg
                pbPt
                title
                content
                blockStyle
                hasAdditionalSpacingOnBottom
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Aboutacf_ContentBlocks_Content_Video {
                fieldGroupName
                bg
                enableVideo
                videoCincopaId
                videoImagePlaceholder {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                enableAction
                action {
                  showAction
                  action {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      models(first: 10) {
        edges {
          node {
            id
            title
            slug
            acf {
              description
              comingSoon
              images {
                action {
                  hoverLabel
                }
              }
            }
            featuredImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
