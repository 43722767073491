import styled, { css } from 'styled-components'

import Container from '../Container'
import media from '../../common/MediaQueries'
import { TEXT_DECOR_TYPES } from '../../constants/textDecorTypes'

const StyledTextDecor = styled(Container)`
  display: none;

  @media ${media.desktop} {
    z-index: 3;
    display: block;
    position: absolute;
    top: 0;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    pointer-events: none;

    ${({ textDecorType }) =>
      textDecorType === TEXT_DECOR_TYPES.left &&
      css`
        left: -1.25rem;
      `}

    ${({ textDecorType }) =>
      textDecorType === TEXT_DECOR_TYPES.right &&
      css`
        right: -1.25rem;
      `}
  }
`

const StyledTextDecorLabel = styled.p`
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 1;
  font-size: 9.375rem;
  letter-spacing: 0.8rem;
  text-transform: uppercase;
  opacity: 0.04;
`

export { StyledTextDecor, StyledTextDecorLabel }
