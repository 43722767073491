import React from 'react'
import PropTypes from 'prop-types'

import { StyledTextDecor, StyledTextDecorLabel } from './styles'
import { TEXT_DECOR_TYPES } from '../../constants/textDecorTypes'
import theme from '../../themes/theme'

const TextDecor = ({ textDecorType, label, color }) => (
  <StyledTextDecor textDecorType={textDecorType} color={color}>
    <StyledTextDecorLabel>{label}</StyledTextDecorLabel>
  </StyledTextDecor>
)

export default TextDecor

TextDecor.defaultProps = {
  textDecorType: TEXT_DECOR_TYPES.left,
  color: theme.colors.black,
}

TextDecor.propTypes = {
  label: PropTypes.string.isRequired,
  textDecorType: PropTypes.oneOf(Object.keys(TEXT_DECOR_TYPES)),
  color: PropTypes.string,
}
